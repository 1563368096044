import React from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as actions from './actions';
import { loadCustomerContacts } from '../App/actions';
import NewOrderAddress from './newOrderAddress';
import NewOrderCustomer from './newOrderCustomer';
import NewOrderProducts from './newOrderProducts';
import NewOrderAdditionalInfo from './NewOrderAdditionalInfo';
import NewOrderBasket from './newOrderBasket';
import {
    selectActiveCategoryTab, selectAddress, selectIsMapLocked, selectShowAlertForSave,
    selectShowSwitchPackageDlg,
    selectSwitchDlgSource
} from './selectors';
import { selectMap, selectDiscountPackages } from '../App/selectors';
import { uploadRequest } from '../../components/UploadFile/actions';
import { saveGeometries, saveUserMapIteration, saveIsBusy } from './actions';
import Form from '../../components/Form';
import PageTitle from './pageTitle';
import { MapIframe } from '../../components/Map/map-iframe';
import SwitchProductDlg from './switchProductDlg';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import homeSaga from './sagas';
import homeReducer from './reducer';
import { compose } from '@reduxjs/toolkit';
//import { RESTART_ON_REMOUNT } from '../../utils/constants';

class CreateOrderInternal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handelGeometriesPictureSave = this.handelGeometriesPictureSave.bind(this);
        this.uploadChannel = 'create-order-upload-channel';
    }

    // static defaultProps = {
    //     dontInit: false
    // };

    componentDidMount() {
        if (this.props.route != undefined) {
            // this is used in quotes and copy-order
            this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
        }

        // if (this.props.dontInit != true) {
             this.props.initCreatePageState();
        // }

        this.props.loadPackagesInfo();
        this.props.loadCustomerContacts();
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
    }

    routerWillLeave(nextLocation) {
        return 'You are about to leave the create order process without creating an order or quote, are you sure?';
    }

    handelGeometriesPictureSave(data) {
        if (data.polygonImage !== undefined) {
            this.props.uploadRequest(data.polygonImage, -1, 'order', this.uploadChannel, { isNotDownloadable: true });
        }

        // remove file from data so we will save it as string in Pandora db
        var meta = {
            geometries: data.geometries,
            geometriesArea: data.geometriesArea,
            grids: data.grids,
            gridsMarker: data.gridsMarker
        };
        this.props.saveGeometries(meta);
        this.props.saveUserMapIteration(true);
    }

    render() {
        return (
            <div>
                <PageTitle><h1>New Order</h1></PageTitle>
                <Form className="content-wrapper details-wrapper">
                    <section id="sectionWidth" className="details-main">
                        {/* Customer Information */}
                        <NewOrderCustomer />
                        {/* Property Information   */}
                        <NewOrderAddress />
                        {/* Map. dont include in this release*/}
                        {(this.props.showMapFeature === "true") ?
                            <MapIframe address={this.props.selectedAddress}
                                pictureGeometriesSave={this.handelGeometriesPictureSave}
                                isMapLocked={this.props.isMapLocked}
                                showAlertForSave={this.props.showAlertForSave}
                                toggleMapSaveButton={this.props.toggleMapSaveButton}
                                saveUserMapIteration={this.props.saveUserMapIteration}
                                saveIsBusy={this.props.saveIsBusy}
                            />
                            : null}
                        {/*Extra info*/}
                        <NewOrderAdditionalInfo />
                        {/* Products */}
                        <NewOrderProducts changeTab={this.props.changeProductCategoryTab}
                            activeTab={this.props.activeTab}
                            selectPackage={this.props.selectPackage}
                            hideDiscountPackages={this.props.hideDiscountPackages}
                        />
                    </section>
                    <NewOrderBasket quoteId={this.props.quoteId} />
                </Form>
                {this.props.showSwitchPackageDlg ?
                    <SwitchProductDlg
                        open={this.props.showSwitchPackageDlg}
                        maxWidth={600}
                        switchDlgSource={this.props.switchDlgSource}
                        switchToSuggestedPackage={this.props.switchToSuggestedPackage}
                        closeSwitchProductDlg={this.props.closeSwitchProductDlg}
                    />
                    : null}
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    activeTab: selectActiveCategoryTab(),
    selectedAddress: selectAddress(),
    showMapFeature: selectMap(),
    hideDiscountPackages: selectDiscountPackages(),
    isMapLocked: selectIsMapLocked(),
    showAlertForSave: selectShowAlertForSave(),
    showSwitchPackageDlg: selectShowSwitchPackageDlg(),
    switchDlgSource: selectSwitchDlgSource()
});

const mapDispatchToProps = ({
    ...actions,
    loadCustomerContacts,
    uploadRequest: uploadRequest,
    saveGeometries: saveGeometries,
    saveUserMapIteration: saveUserMapIteration,
    saveIsBusy: saveIsBusy
})
// Wrap the component to inject dispatch and state into it
//export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateOrder));

var withSaga = injectSaga({ key: 'create-order-internal', saga: homeSaga/*, mode: RESTART_ON_REMOUNT*/ });
var withReducer = injectReducer({ key: 'create-order', reducer: homeReducer });

// Wrap the component to inject dispatch and state into it
export default compose(
  withSaga,
  withReducer,
  connect(mapStateToProps, mapDispatchToProps)
)(CreateOrderInternal);