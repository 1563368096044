import React from 'react';
import ExpandableSection from '../../components/ExpandableSection';
import Modal from '../../components/Modal/index';
import Legend from '../../assets/img/helpv2.png';
//import HelpOutlineIcon from '@mui/material/svg-icons/action/help-outline';
import CropFree from '@mui/icons-material/CropFree';

function getScrollBarWidth() {
    var inner = document.createElement('p');
    inner.style.width = "100%";
    inner.style.height = "200px";

    var outer = document.createElement('div');
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    outer.appendChild(inner);

    document.body.appendChild(outer);
    var w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    var w2 = inner.offsetWidth;
    if (w1 == w2) w2 = outer.clientWidth;

    document.body.removeChild(outer);

    return (w1 - w2);
};

export class MapIframe extends React.Component {
    scrollBarWidth = 0;
    constructor(props) {
        super(props);
        //this.iframe = React.createRef();
        this.getRef = this.getRef.bind(this);
        this.sendSetProperty = this.sendSetProperty.bind(this);
        this.handleHideShowHelp = this.handleHideShowHelp.bind(this);
        this.msgReceived = this.msgReceived.bind(this);
        this.fullScreenMap = this.fullScreenMap.bind(this);
        this.exitFullScreenMap = this.exitFullScreenMap.bind(this);
        this.exitHandler = this.exitHandler.bind(this);


        this.isIE = false;
        this.canSendEventsToMap = false;
        // we will lock and unlock map throught store.
        this.state = {
            showHideHelp: false,
            showBusyDiv: false,
            height: '700',
            showFullScreen: true,
            showAttensionModal: false,
            discardMapMsg: 'The map will not be saved, please upload alternative plan.'
        };
    }

    componentDidMount() {
        this.canSendEventsToMap = false;
        this.scrollBarWidth = getScrollBarWidth();
        const ua = window.navigator.userAgent;
        this.isIE = /MSIE|Trident/.test(ua);

        // subscribe to recieve messages from map
        window.addEventListener("message", this.msgReceived, false);

        if (this.isIE) {
            document.addEventListener('MSFullscreenChange', this.exitHandler, false);
        }
    }

    componentWillUnmount() {
        // remove map event listner. If not removed then every time we load this component we will add new
        // handler and will process events from map multiple times.
        window.removeEventListener("message", this.msgReceived, false);
        if (this.isIE) {
            document.removeEventListener('MSFullscreenChange', this.exitHandler, false);
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.address.easting !== prevProps.address.easting || this.props.address.northing !== prevProps.address.northing) {
            if (this.canSendEventsToMap === true)
                this.sendSetProperty();
            else {
                this.delayInitializeMapProperty();
            }
        }
    }

    delayInitializeMapProperty() {
        var that = this;
        setTimeout(() => {
            if (that.canSendEventsToMap) {
                that.sendSetProperty();
            }
            else {
                that.delayInitializeMapProperty();
            }
        }, 500);
    }

    exitHandler() {
        if (!document.msFullscreenElement) {
            this.setState({ height: '650', showFullScreen: true });
            var event = { eventType: 'fullScreen', data: null };
            this.iframe.contentWindow.postMessage(event, "*");
        }
    }

    sendSetProperty() {
        var data = { easting: this.props.address.easting, northing: this.props.address.northing };
        var event = { eventType: 'setProperty', data: data };
        this.iframe.contentWindow.postMessage(event, "*");
    }

    sendGetMapImage = () => {
        this.setState({ showBusyDiv: true });
        var event = { eventType: 'getMapImageInSize', data: { fileName: "property-plan.png", width: 1200, height: 1600 } };
        this.iframe.contentWindow.postMessage(event, "*");
        this.props.toggleMapSaveButton(false);
        this.stopScroll(false);
        this.props.saveIsBusy(true);
    }

    discardMap = () => {
        this.setState({ showAttensionModal: true });
        var event = { eventType: 'clearAllGeometries' };
        this.iframe.contentWindow.postMessage(event, "*");
        this.props.saveUserMapIteration(true);
    }


    msgReceived(event) {
        if (event.data.eventType === 'mapInitialized') {
            this.canSendEventsToMap = true;
        }
        else if (event.data.eventType === 'mapImage') {
            //because of IE we cannot set name to Blob in iframe. so set it here
            event.data.data.polygonImage.name = 'property-plan.png';
            event.data.data.polygonImage.isNotDownloadable = true;
            this.props.pictureGeometriesSave(event.data.data);
            this.setState({ showBusyDiv: false });
        }
        else if (event.data.eventType === 'siteGeometryChange') {
            var data = JSON.parse(event.data.data.geometries);
            this.props.toggleMapSaveButton(data.geometries.length > 0);
        }
        else if (event.data.eventType === 'showHelp') {
            this.handleHideShowHelp();
        }
    }

    fullScreenMap() {
        var elem = document.getElementById("picframe");
        if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
            this.setState({ height: '98%' });
            var event = { eventType: 'fullScreen', data: "" };
            this.iframe.contentWindow.postMessage(event, "*");
            this.setState({ showFullScreen: false });
        }
    }

    exitFullScreenMap() {
        if (document.msExitFullscreen) {
            document.msExitFullscreen();
            this.setState({ showFullScreen: true });
        }
    }

    handleHideShowHelp() {
        this.setState({ showHideHelp: !this.state.showHideHelp });
    }

    stopScroll(state) {
        //const body = document.getElementsByTagName("BODY")[0];
        const body = document.getElementsByClassName("content")[0];
        if (state) {
            body.style.paddingRight = '17px';// (this.scrollBarWidth) + 'px';
            body.style.overflow = 'hidden';
            return;
        }

        body.style.overflow = 'auto';
        body.style.paddingRight = '0px';
    }

    getRef(el) {
        this.iframe = el;
    }

    render() {
        var url = this.props.url || '/getmap';
        const style = { width: '100%' };

        var btnFullScreen = null;
        if (this.isIE) {
            btnFullScreen = <button type="button" className={this.props.isMapLocked ? 'fullScreenIEBtn lock-map' : 'fullScreenIEBtn'} onClick={() => {
                this.state.showFullScreen ? this.fullScreenMap() : this.exitFullScreenMap();
            }}>
                <div style={{ display: 'inline-flex' }}><CropFree style={{ color: 'white' }} viewBox='0 0 36 36' /><span>FullScreen</span></div>

            </button>;
        }
        return (
            <div>
                <ExpandableSection title='Property Map' isExpandable={true} isMap={true}>
                    <div id="picframe" className="piciframe" onMouseOver={() => this.stopScroll(true)} onMouseLeave={() => this.stopScroll(false)}>
                        {this.state.showBusyDiv ?
                            <div className="save-polygon">Generating plan...</div> : null}
                        {btnFullScreen}
                        <Modal
                            title={'Help'}
                            handleClose={(e) => this.setState({ showHideHelp: false })}
                            icon={<img src={Legend} alt='' style={style} />}
                            msg={
                                <p style={{fontSize:16}}>Please click <a href="https://www.severntrentsearches.com/NewAtlasDocs/Guides/Mapping_Tool_User_Guide_External.pdf" target="blank">here</a> for mapping tool guide.</p>
                            }
                            showModal={this.state.showHideHelp} />
                        <Modal
                            showModal={this.state.showAttensionModal}
                            msg={this.state.discardMapMsg}
                            handleClose={(e) => this.setState({ showAttensionModal: false })}
                        />
                        <iframe id="frame1"
                            height={this.state.height}
                            width="100%"
                            src={url}
                            ref={this.getRef}
                            className={this.props.isMapLocked ? 'lock-map' : ''}>
                        </iframe>
                        {
                            this.props.showAlertForSave ?
                                <div style={{ padding: 10, display: 'inline-flex' }} className="">
                                    <div id="saveAlert" className="alert-for-save" style={{ width: '70%', padding: 5 }}>
                                        {/* If you want to keep your changes, you have to "Save Map" */}
                                        We have automatically located the property and boundary. This is the region that will be searched against unless you amend it. Please confirm if this is correct to progress the order.
                                    </div>
                                    <div style={{ textAlign: 'right', width: '29%', padding: 5 }}>
                                        <button id="savePolygon" type="button" style={{ marginRight: 5 }}
                                            className="button button-small button-green align-left"
                                            onClick={this.sendGetMapImage}>
                                            Save Map
                                        </button>
                                        <button type="button"
                                            className="button button-small button-red align-left"
                                            onClick={this.discardMap}>
                                            Discard Map
                                        </button>
                                    </div>
                                </div>
                                : null
                        }
                        {this.props.isMapLocked === true && this.state.showBusyDiv === false ?
                            <div style={{ textAlign: 'right', padding: 10 }} className="">
                                <span className="alert-for-save">
                                    Plan is saved and map is locked. To unlock please remove the 'property-plan.png' file from uploaded files.
                                </span> </div> : null}
                    </div>
                </ExpandableSection>
            </div>
        );
    }
}
